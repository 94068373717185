/*=========================================================================================
  File Name: _theNavbar.scss
  Description: The navbar styles
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

.vs-navbar.navbar-skelton {
  padding: .8rem 2.2rem;
  width: 100%;
  transition: all .5s;
  z-index: 10000;
}

.navbar-sticky,
.navbar-floating {
  .vx-navbar-wrapper {
    position: fixed;
  }
}

.vx-navbar-wrapper {
  background: linear-gradient(to bottom, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0) 100%);
  background-repeat-x: repeat;
  z-index: 41001;
  width: 100%;
  height: 103px;
  background-repeat-y: no-repeat;
  // position: fixed;
  top: 0;
  // transition: all .5s;

  .vx-navbar {
    .vs-con-items {
      width: 100%;

      .search-full-container {
        background: $white;
      }

      .vx-auto-suggest {
        color: $content-color;
      }
    }


  }
}

@media (min-width: 1201px) {

  // .vx-navbar-wrapper {
  //   width: 100%;
  // }
  .main-vertical {
    .vx-navbar-wrapper {
      width: calc(100% - 260px);
    }
  }
}

.content-area-lg {
  .vx-navbar-wrapper {
    width: calc(100% - 80px);
  }
}

.show-overlay .vx-navbar-wrapper {
  background: linear-gradient(to bottom, rgba(44, 48, 60, 0.9) 44%, rgba(44, 48, 60, 0.43) 73%, rgba(44, 48, 60, 0) 100%);
}


/////////////////////////////////////////////////////////
// NAVBAR VARIANTS
/////////////////////////////////////////////////////////

.navbar-floating {

  .vx-navbar-wrapper {
    @media (min-width: 577px) {
      padding: 1.3rem 2.2rem 2.2rem;
    }
  }

  .vx-navbar {
    border-radius: .5rem;
    padding: .8rem 1rem;

    .search-full-container {
      border-radius: .5rem;
    }
  }
}

.navbar-sticky {
  .vx-navbar-wrapper {
    background: none;

    // .vx-navbar {
    //   .vx-auto-suggest {
    //     .auto-suggest-suggestions-list {
    //       width: calc(100% - 2rem) !important;
    //       margin-left: 1rem;
    //     }
    //   }
    // }
  }

  .content-area-lg {
    .vx-navbar-wrapper {
      .vx-navbar {
        width: 100%;
      }
    }
  }
}

.navbar-static,
.navbar-sticky {
  .vx-navbar-wrapper {
    .vx-navbar {
      .vx-auto-suggest {
        .auto-suggest-suggestions-list {
          width: calc(100% - 2rem) !important;
          margin-left: 1rem;
        }
      }
    }
  }
}

.main-vertical {
  &.navbar-static {
    .vx-navbar-wrapper {
      .vx-navbar {
        z-index: 41001;
      }
    }
  }
}


.navbar-static {
  .vx-navbar-wrapper {
    background: none;
    // position: relative;
    height: auto;
    width: 100%;

    .vx-navbar {
      // background: transparent !important;
      // box-shadow: none;
      position: relative;

      // .vx-auto-suggest {
      //   .auto-suggest-suggestions-list {
      //     width: calc(100% - 2rem) !important;
      //     margin-left: 1rem;
      //   }
      // }
    }
  }

  .router-view {
    padding-top: 1rem;
  }
}

.navbar-hidden {
  &.main-vertical {
    .vx-navbar-wrapper {
      display: none;
    }
  }
}

.search-full-container {
  z-index: 50000;

  .vx-auto-suggest {
    >div {
      height: 100%;
    }

    input[type="text"],
    .input-span-placeholder {
      padding: 1.6rem 3rem !important;
    }

    .vs-input--icon.feather {
      top: 32% !important;
      left: 0.8rem;
    }
  }

  >div.feather-icon {
    position: absolute !important;
  }
}

// Bookmark Dropdown
.bookmark-dropdown {
  z-index: 41002;
}

// navbar update fixes
.navbar-custom {
  .vs-navbar--btn-responsive {
    display: none !important;
  }

  .vs-spacer {
    display: block !important;
  }

  .vs-con-items {
    display: flex !important;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .navbar-sticky {
    .vx-navbar-wrapper {
      .vx-navbar {
        padding: .8rem 2.2rem;
      }
    }
  }

}

@media (max-width: 576px) {
  .vx-navbar-wrapper {
    padding: 1.2rem;
  }

  .navbar-sticky {
    .vx-navbar-wrapper {
      padding: 0;

      .vx-navbar {
        padding: .8rem 1.5rem;
      }
    }
  }

  .navbar-static {
    .vx-navbar-wrapper {
      padding: 0;

      .vx-navbar {
        padding: .8rem 1.5rem;
      }
    }

    &.main-vertical {
      .router-view {
        .router-content {
          margin-top: 0;
        }
      }
    }
  }
}


// BOOKMARK
.starred-page,
.starred-page--more {
  &:hover {
    color: rgba(var(--vs-primary), 1);
  }
}

// I18N
.i18n-dropdown {
  .vs-dropdown--item-link {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 364px) {
    width: 95vw;
    left: 90vw !important;

    .vs-dropdown--menu--after {
      display: none;
    }
  }
}

// CART & NOTIFICATIONS DROPDOWN
.cart-dropdown {
  width: 365px;

  .cart-dropdown-item-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: .35s;
  }
}

.notification-dropdown,
.cart-dropdown {
  width: 365px;

  // Full width notification in mobile devices
  @media screen and (max-width: 500px) {
    width: 95vw;
    left: 97.5vw !important;

    .vs-dropdown--menu--after {
      display: none;
    }
  }

  .notification {
    &:hover {
      background-color: #f7f7f7;
    }
  }

  .checkout-footer,
  .notification-footer {
    background-color: #f8f8f8;
  }
}

.scroll-area--nofications-dropdown,
.scroll-area--cart-items-dropdowm {
  position: relative;
  margin: auto;
  width: 100%;
  max-height: 25rem;

  &:not(.ps) {
    overflow-y: auto;
  }
}

.vx-navbar-dropdown {
  @media screen and (max-height: 334px) {
    top: calc(var(--vh, 1vh) * 100 - 82.5vh) !important;

    .vs-dropdown--menu--after {
      display: none;
    }
  }
}
