.con-vs-popup .vs-popup {
  width: 80% !important;
}

.unsaved-changes-modal.con-vs-popup .vs-popup {
  max-width: 560px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lightgray .input-select,
.datepicker-placeholder ::placeholder {
  color: #a1a1a1 !important;
}

.recording-pop-up.con-vs-popup .vs-popup {
  max-width: 700px !important;
}

.popup-small.con-vs-popup .vs-popup {
  max-width: 850px !important;
}

.status-pop-up.con-vs-popup .vs-popup {
  max-width: 1050px !important;
}

.time-input.vs-con-input-label {
  width: 70px !important;
}

.invalid-date input {
  border: solid rgb(var(--vs-danger)) 1px;
}

div.public-work-time-modal {
  div.vs-popup {
    max-width: 700px !important;
  }
}

#payroll-container {
  .accounting-container {
    .vs-component.vs-con-input-label.vs-input {
      max-width: 150px;

      input.vs-input--input.small {
        text-align: center;
      }
    }
  }

  input.vs-input--input.small {
    font-size: 1rem !important;
  }
}

::selection {
  background-color: #333;
  color: #f1f1f1;
}

.vs-select--input {
  border-color: rgba(0, 0, 0, 0.2);
}
