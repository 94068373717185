/*=========================================================================================
    File Name: _contextMenu.scss
    Description: Styles for context menu
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

@import '../_variables.scss';

.v-context {
  // background: $white !important;

  padding: 0 !important;
  border-radius: .4rem !important;
  overflow: hidden;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.14) !important;
  z-index: 51000 !important;

  li {
    a {
      color: inherit !important;
      padding: .7rem 1rem !important;

      &:focus,
      &:hover {
        background: #eee !important;
      }
    }
  }

  // ul li:hover {
  //  background: $primary !important;
  // }
}

.theme-dark {
  .v-context {
    background: $theme-light-dark-bg !important;

    // ul li:hover {
    //  background: $theme-light-dark-bg !important;
    // }

    li {
      a {

        color: $theme-dark-text-color !important;

        &:focus,
        &:hover {
          background: $theme-dark-bg !important;
        }
      }
    }
  }

}
