/*=========================================================================================
    File Name: _quillEditor.scss
    Description: Styles for quill editor externsion.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


@import "../_variables.scss";

.quill-editor{
    .ql-bubble{
        .ql-tooltip{
            z-index: 51000;
        }
    }
}

.theme-dark {
  .quill-editor{
    .ql-snow {
      &.ql-toolbar,
      &.ql-container {
        border-color: $theme-dark-border-color;
      }
    }
  }
}
