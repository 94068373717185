/*=========================================================================================
  File Name: _awesomeSwiper.scss
  Description: Styles for awesome swiper plugin
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


.swiper-pagination-bullet-active {
  background: rgba(var(--vs-primary),1) !important;
}

.swiper-pagination-progressbar {
  .swiper-pagination-progressbar-fill {
    background: rgba(var(--vs-primary),1) !important;
  }
}
